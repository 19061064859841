<template>
  <div v-if="session">
    <v-data-table
      :headers="headers"
      :items="data"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>

        <CModalEdit />
        <CModalHapus />
      </template>

      <template v-slot:[`item.gaji`]="{ item }">
        <div class="py-1">Rp. {{ fAngka(item.gaji) }}</div>
      </template>

      <template v-slot:[`item.unor`]="{ item }">
        <div class="py-1">
          {{ item.unor.induk.nama }} <br />
          <small>
            {{ item.unor.nama }}
          </small>
        </div>
      </template>

      <template v-slot:[`item.noperker`]="{ item }">
        <div class="py-1">
          {{ item.noperker }} <br />
          <small>
            {{ item.noperker_tgl }}
          </small>
        </div>
      </template>

      <template v-slot:[`item.dokumen`]="{ item }">
        <v-row dense>
          <div v-if="item.doc != null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="ddoc(item.id)" v-bind="attrs" v-on="on">
                  <v-icon color="red"> mdi-file-pdf-box </v-icon>
                </v-btn>
              </template>
              <span> Dokumen </span>
            </v-tooltip>
          </div>

          <div v-if="item.doc_spmt != null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="dspmt(item.id)" v-bind="attrs" v-on="on">
                  <v-icon color="red"> mdi-file-pdf-box </v-icon>
                </v-btn>
              </template>
              <span> Dokumen </span>
            </v-tooltip>
          </div>
        </v-row>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="viewItem(item)"> mdi-eye </v-icon>
        <v-icon
          small
          class="mx-2"
          @click="editItem(item)"
          v-if="session.authorities.some((item) => item === 'CRUD_PERKER')"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          v-if="session.authorities.some((item) => item === 'CRUD_PERKER')"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div v-if="sdata">
      <v-divider class="mb-5"></v-divider>

      <v-card outlined>
        <v-toolbar color="blue-grey lighten-5" dense flat>
          <v-toolbar-title> Detail Data </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="sdata = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="pa-2">
          <CView :vitem="vdata" />
        </div>
      </v-card>
    </div>

    <v-dialog v-model="modalPDF" :max-width="dekstop">
      <v-card height="90vh" style="overflow: hidden">
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePDF()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="pa-2">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="lbtn"
          ></v-progress-linear>

          <div v-else>
            <embed
              :src="defaultPDF"
              type="application/pdf"
              style="height: 80vh; width: 100%"
            />
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import lib from "@/helper/lib.js";
import Auth from "@/helper/auth.js";

import CView from "@/components/asn/profil/pppk/rwperker/View";
import CModalEdit from "@/components/asn/profil/pppk/rwperker/ModalEdit";
import CModalHapus from "@/components/asn/profil/pppk/rwperker/ModalHapus";

import refresh from "@/store/asn/pppk/rwperker/view";
import modal_edit from "@/store/asn/pppk/rwperker/modal_edit";
import modal_hapus from "@/store/asn/pppk/rwperker/modal_hapus";

export default {
  components: {
    CView,
    CModalEdit,
    CModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refresh.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refresh.state.alertMassage;
      },
    },

    success: {
      get() {
        return refresh.state.success;
      },

      set(value) {
        refresh.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refresh.state.alertBerhasil;
      },
      set(value) {
        refresh.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refresh.state.alertGagal;
      },

      set(value) {
        refresh.commit("gagalAlert", value);
      },
    },

    headers() {
      const headers = [
        { text: "Jenis Jabatan", value: "jenis_jabatan.nama" },
        { text: "Nama Jabatan", value: "nama_jabatan" },
        { text: "TMT Jabatan", align: "start", value: "tmt_jabatan" },
        { text: "Gaji Pokok", align: "start", value: "gaji" },
        { text: "Unit Kerja", value: "unor" },
        { text: "Nomor Perjanjian Kerja", value: "noperker" },
        {
          text: "File",
          value: "dokumen",
          width: "100px",
          align: "center",
          sortable: false,
        },
      ];

      if (this.session.authorities.some((item) => item === "CRUD_PERKER")) {
        headers.push({
          text: "Actions",
          value: "actions",
          align: "center",
          width: "100px",
          sortable: false,
        });
      }

      return headers;
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    loading: false,
    sdata: false,

    session: "",
    get_nip: "",

    modalPDF: false,
    lbtn: false,
    dekstop: "75%",
    defaultPDF: "",
    namaPDF: "",

    data: [],
    vdata: {},
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      const url =
        process.env.VUE_APP_ASN + "pppk/perjanjiankerja/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.loading = false;
          refresh.commit("refreshData", false);
          this.data = response.data.mapData.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    viewItem(item) {
      this.vdata = item;
      this.sdata = true;
    },

    editItem(item) {
      modal_edit.commit("toggleModal", true);
      modal_edit.commit("editModal", item);
    },

    deleteItem(item) {
      modal_hapus.commit("toggleModal", true);
      modal_hapus.commit("hapusModal", item);
    },

    ddoc(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = "Dokumen";

      const url =
        process.env.VUE_APP_API +
        "/idasn/pppk/perjanjiankerja/doc/" +
        this.get_nip +
        "/" +
        value +
        "/doc";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    dspmt(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = "Dokumen";

      const url =
        process.env.VUE_APP_API +
        "/idasn/pppk/perjanjiankerja/doc/" +
        this.get_nip +
        "/" +
        value +
        "/doc_spmt";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    fAngka(value) {
      return lib.rupiah(value);
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
