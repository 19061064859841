import axios from "axios";
const url = process.env.VUE_APP_ASN

export default {
    baseURL: url,

    Config: (token) => {
        return {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }
    },

    Update: (token) => {
        return {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        }
    },

    Auth: {
        headers: {
            'Content-Type': 'application/json',
        },

        body: {
            grant_type: 'simASN',
        },

        cek: async (token) => {
            let url = url + 'oauth'
            let headers = {
                Authorization: 'Bearer ' + token,
            }
            try {
                const response = await axios.get(url, { headers: headers })
                return response.data
            } catch (error) {
                return error.response.data
            }
        }

    }
}