<template>
  <div>
    <v-row class="mb-n10" v-if="vitem.jenis_jabatan">
      <v-col cols="12" md="2" class="mt-3">
        <span>Jenis Jabatan</span>
      </v-col>

      <v-col cols="12" md="10">
        <v-text-field
          v-model="vitem.jenis_jabatan.nama"
          class="mt-2"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>TMT Jabatan</span>
      </v-col>

      <v-col cols="12" md="10">
        <v-text-field
          v-model="vitem.tmt_jabatan"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Gaji Pokok</span>
      </v-col>

      <v-col cols="12" md="10">
        <v-text-field
          v-model="vitem.gaji"
          class="mt-2"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Unit Kerja</span>
      </v-col>

      <v-col cols="12" md="5" v-if="vitem.unor">
        <v-text-field
          v-model="vitem.unor.nama"
          class="mt-2"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="5" v-if="vitem.unor.induk">
        <v-text-field
          v-model="vitem.unor.induk.nama"
          class="mt-2"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>Nomor Perjanjian Kerja</span>
      </v-col>
      <v-col cols="12" md="5">
        <v-text-field
          v-model="vitem.noperker"
          class="mt-2"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="5">
        <v-text-field
          v-model="vitem.noperker_tgl"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mb-n10">
      <v-col cols="12" md="2" class="mt-3">
        <span>MHPK</span>
      </v-col>

      <v-col cols="12" md="2">
        <v-text-field
          v-model="vitem.mhpk_tahun"
          class="mt-2"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="vitem.mhpk_mulai"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal Mulai"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="vitem.mhpk_selesai"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal Selesai"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2" class="mt-3">
        <span>SPMT</span>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="vitem.no_spmt"
          class="mt-2"
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          v-model="vitem.tgl_spmt"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="Tanggal"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          v-model="vitem.tmt_spmt"
          append-icon="mdi-calendar"
          class="mt-2"
          hint="TMT"
          persistent-hint
          outlined
          dense
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  props: ["vitem"],

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    stat_peng: [
      {
        id: "pertama",
        nama: "Pertama",
      },
      {
        id: "perpanjangan",
        nama: "Perpanjangan",
      },
    ],
  }),
};
</script>
