<template>
  <div>
    <v-card flat>
      <v-app-bar color="primary" short dense dark flat>
        <v-toolbar-title>Riwayat Perjanjian Kerja</v-toolbar-title>
        <v-spacer></v-spacer>

        <div class="mr-3 mt-1" v-if="CRUD_PERKER">
          <rwperkerModalAdd />
        </div>

        <div style="max-width: 300px">
          <v-tabs v-model="tab" right dark background-color="primary">
            <v-tab href="#tab-1" @click="getSIMASN()">
              <h5>IDASN</h5>
            </v-tab>

            <!-- <v-tab href="#tab-2" @click="getSAPK()">
              <h5>SIASN</h5>
            </v-tab> -->
          </v-tabs>
        </div>
      </v-app-bar>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" class="pa-5">
          <rwperkerSIMASN />
        </v-tab-item>
        <!-- <v-tab-item value="tab-2" class="pa-5">
          <rwhukdisSAPK />
        </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getData from "@/store/asn/pppk/rwperker/tabs";

import rwperkerSIMASN from "@/components/asn/profil/pppk/rwperker/rwperker";
// import rwcutiSAPK from '@/components/asn/profil/rwcuti/SAPK_rwcuti_Modal'

import rwperkerModalAdd from "@/components/asn/profil/pppk/rwperker/ModalAdd";

export default {
  name: "DataUtama",

  components: {
    rwperkerSIMASN,
    // rwhukdisSAPK,
    rwperkerModalAdd,
  },

  computed: {
    dataSAPK: {
      get() {
        return getData.state.tabSAPK;
      },

      set(value) {
        getData.commit("getSAPK", value);
      },
    },
  },

  data: () => ({
    tab: null,
    token: Cookie.get("token"),
    session: "",

    CRUD_ADMINISTRATOR: false,
    CRUD_PERKER: false,
    READ_ASN_ALL_OPD: false,
    READ_ASN_GROUP_OPD: false,
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.cekauth();
  },

  methods: {
    getSAPK() {
      getData.commit("getSAPK", true);
      getData.commit("getSIMASN", false);
    },

    getSIMASN() {
      getData.commit("getSIMASN", true);
      getData.commit("getSAPK", false);
    },

    cekauth() {
      let arr = this.session.authorities;
      this.CRUD_ADMINISTRATOR = arr.some(
        (item) => item === "CRUD_ADMINISTRATOR"
      );
      this.CRUD_PERKER = arr.some((item) => item === "CRUD_PERKER");
      this.READ_ASN_ALL_OPD = arr.some((item) => item === "READ_ASN_ALL_OPD");
      this.READ_ASN_GROUP_OPD = arr.some(
        (item) => item === "READ_ASN_GROUP_OPD"
      );
    },
  },
};
</script>
