import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },

    data: {
      id: '',
      jenis_jabatan: {
        id: '',
        nama: '',
      },
      nama_jabatan: '',
      tmt_jabatan: '',
      gaji: '',
      unor: {
        id: '',
        nama: '',
        atasan: {
          id: '',
          nama: ''
        },
        induk: {
          id: '',
          nama: ''
        }
      },
      noperker: '',
      noperker_tgl: '',
      mhpk_tahun: '',
      mhpk_mulai: '',
      mhpk_selesai: '',
      doc: '',
      userId: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },

    editModal(state, value) {
      state.data = value
    },

    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})